import { onMounted, onBeforeUnmount, ref, computed, onActivated } from "vue";
import { debounce } from 'loadsh';

export default () => {
    // 分界值
    const breakPoint = 1080;

    // 屏幕宽度
    const screenWidth = ref(0);
    // 根据屏幕宽度判断设备
    const device = computed(() => {
        if (!screenWidth.value) return null;
        if (screenWidth.value < breakPoint) {
            return 'PHONE';
        } else {
            return 'PC';
        }
    })

    // 判断是否是PC
    function isPC() {
        return window.innerWidth >= breakPoint;
    }

    // 判断是否是PHONE
    function isPHONE() {
        return window.innerWidth < breakPoint;
    }

    // 获取屏幕宽度
    function setScreenWidth() {
        screenWidth.value = window.innerWidth;
    }

    const handleResize = debounce(() => {
        setScreenWidth();
    }, 300)

    onActivated(() => {
        document.body.style.minWidth = '0px';
        setScreenWidth();
        window.addEventListener('resize', handleResize);
    })

    onMounted(() => {
        document.body.style.minWidth = '0px';
        setScreenWidth();
        window.addEventListener('resize', handleResize);
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize);
        document.body.style.minWidth = '1200px';
        screenWidth.value = 0;
    })

    return { device, isPC, isPHONE }
}