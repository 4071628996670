<template>
    <div class="bd_container">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
.bd_container {
    width: 1175px;
    padding: 0 48px;
    margin: 0 auto;
    background-color: #f1f1f1;
}
</style>