import { apiFreeWeeklyJsSign } from '@/request/api';
import wx from 'weixin-js-sdk'

export default () => {
    // 设置分享内容
    async function setShareContent(message) {
        // 获取 User Agent
        const userAgent = navigator.userAgent.toLowerCase();
        // 判断是否在微信中打开
        if (userAgent.indexOf('micromessenger') === -1) {
            return;
        }
        const { data } = await apiFreeWeeklyJsSign({ url: window.location.href.split('#')[0] });
        wx.config({
            // debug: process.env.NODE_ENV === 'development', // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            debug: false,
            appId: data?.appId, // 必填，公众号的唯一标识
            timestamp: data?.timestamp, // 必填，生成签名的时间戳
            nonceStr: data?.nonceStr, // 必填，生成签名的随机串
            signature: data?.signature,// 必填，签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        })
        wx.ready(() => {
            const shareData = {
                title: message.title || window.location.href, // 分享标题
                desc: message.desc || '每周餐饮市场事件、调味品厂商和餐饮品牌动态', // 分享描述
                link: message.link || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: message.imgUrl || 'https://goalyeah.obs.cn-southwest-2.myhuaweicloud.com:443/20231031%2FUlogo_A02_1698735254169.png', // 分享图标
                success: () => { }
            };
            wx.updateAppMessageShareData(shareData);// 分享给朋友
            wx.updateTimelineShareData(shareData);// 分享到朋友圈
        })
    }

    return { setShareContent }
}