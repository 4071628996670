<template>
    <div class="show_month">
        <ul>
            <li v-for="item in options" :key="item" :class="handleClass(item)">
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script setup>
const props = defineProps({
    months: { type: Array, default: [] },
})
const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

function handleClass(item) {
    const months = props.months.map(m => Number(m));
    return months.includes(item) ? 'active' : ''
}
</script>

<style lang="scss" scoped>
.show_month {
    ul {
        display: flex;
        align-items: center;

        li {
            font-size: 12px;
            color: #727272;
            margin-right: 18px;
            font-weight: bold;
            user-select: none;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                color: #fff;
            }
        }
    }
}
</style>