<template>
    <div class="customer_week_report_header">
        <div class="content">
            <div class="logo">
                <h1>餐饮资讯</h1>
                <p>WEEKLY HOT NEWS</p>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.customer_week_report_header {

    width: 100%;
    height: var(--week-pc-header-height);
    background: url(~@/assets/img/customer-week-report/header_bg.png) no-repeat center;

    .content {
        width: 1080px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        position: relative;

        .logo {
            width: 280px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: url(~@/assets/img/customer-week-report/logo.png) no-repeat center;

            h1 {
                font-size: 44px;
                color: var(--week-primary-color);
                margin-bottom: 9px;
                line-height: 1em;
                font-weight: bold;
            }

            p {
                font-size: 16px;
                color: var(--week-primary-color);
                font-weight: bold;
            }
        }
    }
}
</style>